import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import ContextProvider from '~/provider/ContextProvider'

import { GlobalStyle } from '~/utils/styles'
import Navigation from '~/components/Navigation'
import SlideoutMenu from '~/components/SlideoutMenu'
import PopupCart from '~/components/PopupCart'
import Footer from '~/components/Footer'

import './layouts.css'

const Wrapper = styled.div`
  margin: 0 auto;
  background: #fff;
`

const Layout = ({ path, children }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <ContextProvider>
      <GlobalStyle />
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div
            style={{
              overflowX: 'hidden',
              position: 'relative',
            }}
          >
            <Navigation
              siteTitle={data.site.siteMetadata.title}
              openMenu={setMenuVisible}
              setPopupVisible={setPopupVisible}
              isPopupVisible={popupVisible}
              isMenuVisible={menuVisible}
              location={path}
            />
            <SlideoutMenu
              isVisible={menuVisible}
              setMenuVisible={setMenuVisible}
            />
            {popupVisible && (
              <PopupCart
                isVisible={popupVisible}
                setPopupVisible={setPopupVisible}
              />
            )}
            <Wrapper>{children}</Wrapper>
            <Footer />
          </div>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
