import React from 'react'
import PropTypes from 'prop-types'

import { StyledButton } from './styles'

const Button = props => {
  const {
    color,
    children,
    onClick,
    className,
    checkout,
    disable,
    popupCart,
  } = props

  return (
    <StyledButton
      color={color}
      onClick={disable ? () => {} : onClick}
      disable={disable}
      className={className}
      popupCart={popupCart}
      checkout={checkout === true}
    >
      {children}
    </StyledButton>
  )
}

export default Button

Button.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  className: PropTypes.string,
  checkout: PropTypes.bool,
}
