import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const SlideoutMenuContainer = styled.div`
  transition: left 400ms ease-in-out;
  width: 210px;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  left: ${props => (props.isVisible ? '0px' : '-270px')};
`

export const MenuItem = styled(Link)`
  transition: 300ms;
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 100;
  padding: 10px 0;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`
export const MenuItemContact = styled.a`
  transition: 300ms;
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 100;
  padding: 10px 0;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`

export const SubmenuItem = styled(Link)`
  transition: 300ms;
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: 100;
  padding: 10px 0;
  text-decoration: none;
  margin-left: 10px;
  &:hover {
    opacity: 0.8;
  }
`
