import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import Button from '../Button'
import {
  PopupCartContainer,
  LineItemContainer,
  LineItem,
  ImageLink,
  LineItemImg,
  LineItemInfo,
  VariantTitle,
  TitleLink,
  LineItemTitle,
  QuantityPriceRow,
  QuantityText,
  PriceContainer,
  PriceText,
  EmptyCartText,
} from './styles'
import { readableSubtotalPrice } from '../../utils'

const lineItemPrice = (currencyCode, quantity, price, discount) => {
  const applyDiscount = discount.length
    ? discount.reduce((prevVal, currentVal) => {
        return prevVal + parseFloat(currentVal.allocatedAmount.amount)
      }, 0)
    : 0
  return Intl.NumberFormat(undefined, {
    currency: currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(quantity * price - applyDiscount)
}

const PopupCart = ({ isVisible, setPopupVisible }) => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const [transition, setTransition] = useState(false)
  const popUpRef = useRef(null)

  console.log('checkout:', checkout)

  /**
   * @desc Unmount popupCart after opacity fade out
   */
  const onTransitionEnd = () => {
    if (!transition) {
      setPopupVisible(false)
    }
  }

  const handleClickOutside = e => {
    if (popUpRef.current && !popUpRef.current.contains(e.target)) {
      if (isVisible) setTransition(false)
    }
  }
  const handleCheckout = () => {
    const checkoutUrl = checkout.webUrl.replace(
      /sood-co\.myshopify\.com/,
      'checkout.sood.co'
    )

    window.location.href = checkoutUrl
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind event
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    setTransition(true)
  }, [isVisible])

  return (
    <PopupCartContainer
      onTransitionEnd={e => {
        e.persist()
        onTransitionEnd()
      }}
      ref={popUpRef}
      transition={transition}
    >
      {checkout.lineItems.length > 0 ? (
        <>
          <LineItemContainer>
            {checkout.lineItems.map(line_item => {
              let { quantity, title, discountAllocations, variant } = line_item
              let { priceV2, image } = variant
              return (
                <LineItem key={line_item.id.toString()}>
                  <ImageLink to={`/product/${variant.product.handle}`}>
                    <LineItemImg src={image.src} />
                  </ImageLink>
                  <LineItemInfo>
                    <TitleLink to={`/product/${variant.product.handle}`}>
                      <LineItemTitle>{title}</LineItemTitle>
                    </TitleLink>
                    <VariantTitle>{variant.title}</VariantTitle>
                    <QuantityPriceRow>
                      <QuantityText>({quantity})</QuantityText>
                      <PriceContainer>
                        {discountAllocations.length ? (
                          <PriceText discount={true}>
                            {lineItemPrice(
                              priceV2.currencyCode,
                              quantity,
                              priceV2.amount,
                              []
                            )}
                          </PriceText>
                        ) : (
                          ''
                        )}
                        <PriceText>
                          {lineItemPrice(
                            priceV2.currencyCode,
                            quantity,
                            priceV2.amount,
                            discountAllocations
                          )}
                        </PriceText>
                      </PriceContainer>
                    </QuantityPriceRow>
                  </LineItemInfo>
                </LineItem>
              )
            })}
          </LineItemContainer>
          <div style={{ padding: '20px 30px 0 30px' }}>
            <Link to={`/cart`}>
              <Button
                popupCart={true}
                color="black"
                disable={checkout.lineItems.length === 0}
                onClick={() => {
                  setTransition(false)
                }}
              >
                SHOPPING CART
              </Button>
            </Link>
            <Button
              popupCart={true}
              onClick={() => handleCheckout()}
              color="black"
              disable={checkout.lineItems.length === 0}
            >
              {`CHECKOUT \u2022 ${readableSubtotalPrice(
                checkout.currencyCode,
                checkout.subtotalPriceV2.amount
              )}`}
            </Button>
            {/* Afterpay Placement - Start */}
            <afterpay-placement
              data-locale="en_US"
              data-currency="USD"
              data-size="sm"
              data-badge-theme="mint-on-black"
              data-amount={checkout.subtotalPriceV2.amount}
              style={{
                fontWeight: 300,
                marginBlockStart: '20px',
                marginBlockEnd: '0px',
                textAlign: 'center',
              }}
            />
            {/* Afterpay Placement - End */}
          </div>
        </>
      ) : (
        <EmptyCartText>Your shopping cart is empty.</EmptyCartText>
      )}
    </PopupCartContainer>
  )
}

export default PopupCart
