import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

const colorVariants = {
  white: {
    color: 'var(--main-color)',
    background: 'var(--secondary-color)',
  },
  black: {
    color: 'var(--secondary-color)',
    background: 'var(--main-color)',
  },
}

export const StyledButton = styled.button`
  padding: 12px 7px;
  font-size: 14px;
  font-family: inherit;
  border: none;
  letter-spacing: 1px;

  &.pdp-cta {
    padding: 20px 70px;

    @media (max-width: ${breakpoints.s}px) {
      width: 100%;
    }
  }

  &.homepage-cta {
    padding: 20px 70px;
    position: absolute;
    width: fit-content;
    top: 74%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: ${breakpoints.s}px) {
      padding: 15px 50px;
      top: 75%;
    }
  }

  ${props => colorVariants[props.color] || colorVariants['white']}
  ${props =>
    props.checkout
      ? `
    padding: 20px 70px;
    @media (max-width: ${breakpoints.s}px) {
      width: 100%;
    }`
      : ``}
  ${props => (props.disable ? `background: var(--accent-color);` : ``)}
  ${props => (props.popupCart ? `width: 100%; margin-top: 10px;` : ``)}
  &:hover {
    ${props => (props.disable ? `` : `cursor: pointer`)};
  }
`
