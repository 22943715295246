import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const PopupCartContainer = styled.div`
  transition-property: opacity;
  transition-duration: 300ms;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px 10px;
  width: 300px;
  position: fixed;
  right: 45px;
  top: 70px;
  z-index: 100;
  color: var(--secondary-color);
  opacity: 0;
  ${({ transition }) => (transition ? 'opacity: 1;' : '')}

  @media (max-width: 405px) {
    right: 50%;
    top: 70px;
    transform: translate(50%, 0%);
  }
`
export const LineItemContainer = styled.div`
  max-height: 300px;
  padding: 0 10px;
  overflow-y: auto;
`

export const LineItem = styled.div`
  border: 1px solid var(--secondary-color);
  padding: 15px 10px;
  display: flex;
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0px;
  }
`

export const ImageLink = styled(Link)`
  width: 75px;
  height: 75px;
  display: flex;
`

export const LineItemImg = styled.img`
  width: inherit;
  height: auto;
  object-fit: cover;
`

export const LineItemInfo = styled.div`
  width: 75%;
  color: var(--secondary-color);
  margin-left: 10px;
`

export const TitleLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`

export const LineItemTitle = styled.div`
  font-size: 16px;
`

export const VariantTitle = styled.div`
  font-size: 14px;
`
export const QuantityPriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const QuantityText = styled.div`
  font-size: 14px;
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PriceText = styled.div`
  font-size: 14px;
  ${({ discount }) =>
    discount ? 'text-decoration: line-through; margin-right: 5px' : ''}
`

export const EmptyCartText = styled.h3`
  text-align: center;
  color: var(--secondary-color);
  font-weight: 300;
`
