import styled from '@emotion/styled'

export const HeadlineContainer = styled.div`
  background: #000;
  mix-blend-mode: normal;
  position: fixed;
  z-index: 100;
  padding: 11px 0;
  width: 100%;
`
export const Headline = styled.h2`
  color: var(--secondary-color);
  letter-spacing: 3px;
  font-weight: 300;
  font-size: 15px;
  width: fit-content;
  margin: auto;
  padding: 0 5%;
  text-align: center;
`
