import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import {
  FormContainer,
  FormTitle,
  Form,
  FormInput,
  FormSubmit,
  FormResults,
} from './styles'

const SubmitArrow = () => {
  return (
    <svg height="10px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <polygon points="0 0, 0 100, 86 50" />
    </svg>
  )
}

const MailChimpForm = () => {
  const [email, setEmail] = useState('')
  const [result, setResult] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(email)
    setResult(result)
  }

  const handleChange = e => {
    setEmail(e.target.value)
  }

  return (
    <FormContainer>
      <FormTitle>Subscribe to our newsletter</FormTitle>
      <Form
        onSubmit={e => {
          handleSubmit(e)
        }}
      >
        <FormInput
          id="outlined-email-input"
          placeholder="Email Address"
          label="Email"
          type="email"
          name="email"
          autoComplete="email"
          onChange={e => handleChange(e)}
        />
        <FormSubmit color="primary" label="Submit" type="submit">
          <SubmitArrow />
        </FormSubmit>
      </Form>
      {result ? (
        <FormResults
          dangerouslySetInnerHTML={{ __html: result.msg }}
        ></FormResults>
      ) : (
        ''
      )}
    </FormContainer>
  )
}

export default MailChimpForm
