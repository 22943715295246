import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '../../utils/styles'

// MAX-WIDTH HAS TO BE MANUALLY ADJUSTED BASED ON LENGTH OF BANNER
// margin-top 57px for 2 lines, 74 for 3 lines promo
export const Wrapper = styled.div`
  margin-bottom: 1.45rem;
  z-index: 99;
  position: fixed;
  width: 100%;
  ${props =>
    props.isHomePage ? 'mix-blend-mode: difference;' : 'background: #fff;'}

  ${props =>
    props.hasPromo ? 'margin-top: 40px;' : ''}

  @media (max-width: 413px) {
    ${props => (props.hasPromo ? 'margin-top: 74px;' : '')}
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.3rem;
  margin: 0 auto;
`

export const IconContainer = styled.div`
  transition: 300ms;
  color: var(--main-color);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  ${props => (props.isMenuVisible ? 'opacity: 0;' : '')}

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
    align-self: center;
  }
`
export const MenuLink = styled(Link)`
  transition: 300ms;
  color: var(--main-color);
  text-decoration: none;
  font-size: rem;
  font-weight: bold;
  ${props => (props.isMenuVisible ? 'opacity: 0;' : '')}

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
  }
`

export const CartButton = styled.button`
  border: 0;
  background: transparent;
  width: auto;
  cursor: pointer;
  align-self: center;
`

export const CartCounter = styled.span`
  ${props =>
    props.isHomePage
      ? 'color: var(--main-color);background-color: var(--secondary-color);'
      : 'color: var(--secondary-color);background-color: var(--main-color);'}
  border-radius: 11px;
  padding: 2px 4px;
  font-size: 11px;
  float: right;
  margin: -10px;
  z-index: 20;
  font-weight: 400;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 12px;
    padding: 2px 5px;
  }
`
