import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const FormContainer = styled.div`
  max-width: 360px;
  width: 100%;
  display: inline-block;
  margin-right: auto;

  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    display: block;
    margin: 0 auto;
  }
`

export const FormTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`

export const Form = styled.form`
  background: #fff;
  display: flex;
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
    margin: 0 auto;
  }
`

export const FormInput = styled.input`
  border: none;
  padding: 10px;
  font-family: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  width: 100%;
  max-width: 300px;

  &:focus {
    outline: none;
  }
`

export const FormSubmit = styled.button`
  background: none;
  border: none;
  padding: 10px 15px;

  &:hover {
  }
`

export const FormResults = styled.div`
  font-size: 14px;
  margin-top: 5px;

  & a {
    color: var(--secondary-color);
  }
`
