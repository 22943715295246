import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

export const breakpoints = {
  xs: 480,
  s: 576,
  m: 768,
  l: 935,
  xl: 1200,
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      body {
        margin: 0;
      }
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
    `}
  />
)

export const Img = styled(GatsbyImage)`
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  div:first-of-type {
    ${({ fixedPadding }) =>
      fixedPadding ? 'padding-bottom: 25% !important;' : ''}
  }
`

export const Container = styled.div`
  // padding-top: 100px;
  background: #fff;
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px;
  @media (max-width: ${breakpoints.m}px) {
    padding: 15px;
  }
`
// if promo banner use 125px
export const TwoColumnGrid = styled.div`
  margin-top: 125px;
  // margin-top: 73px;
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
  position: relative;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Spacing = styled.div`
  height: 10vh;
  @media (max-width: ${breakpoints.m}px) {
    height: 10vh;
  }
`
