import React from 'react'

import ContactUsIcon from './../../assets/ContactUs-Icon.inline.svg'
import IGIcon from './../../assets/IG-Icon-White.inline.svg'
import MailChimpForm from '../MailChimpForm'

import {
  FooterContainer,
  FooterWrapper,
  FooterMenuContainer,
  ContactUsText,
  ContactUsMenuItem,
  MenuLink,
  ContactUsContainer,
  CopyrightTextContainer,
} from './styles'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <MailChimpForm />
        <FooterMenuContainer>
          <ContactUsContainer>
            <MenuLink to={'/faqs'}>FAQS</MenuLink>
            <ContactUsMenuItem href="mailto:sood.clothing.co@gmail.com">
              <ContactUsText>CONTACT US</ContactUsText> <ContactUsIcon />
            </ContactUsMenuItem>
            <ContactUsMenuItem
              href="https://www.instagram.com/sood.clothing/"
              target="_blank"
            >
              <ContactUsText>FOLLOW US</ContactUsText> <IGIcon />
            </ContactUsMenuItem>
          </ContactUsContainer>
        </FooterMenuContainer>
      </FooterWrapper>
      <CopyrightTextContainer>
        Sood Clothing LLC. Copyright {new Date().getFullYear()}
      </CopyrightTextContainer>
    </FooterContainer>
  )
}

export default Footer
