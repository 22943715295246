import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '../../utils/styles'

export const FooterContainer = styled.footer`
  padding: 35px 50px 20px;
  background-color: #000;
  color: var(--secondary-color);
  font-size: 13px;
  margin-top: 10px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 35px;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 35px 15px 20px;
  }
`

export const FooterMenuContainer = styled.div`
  width: 40%;
  align-self: flex-end;
  max-width: 600px;

  @media (max-width: ${breakpoints.xl}px) {
    width: 50%;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    margin: 0 auto;
  }
`

export const FooterWrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const CopyrightTextContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  flex-grow: 4;
  font-size: 12px;

  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin: 40px 0 20px;
  }
`

export const CopyrightText = styled.p`
  font-size: 12px;
`

export const ContactUsContainer = styled.div`
  flex-grow: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    display: block;
  }
`

export const ContactUsText = styled.div`
  margin-right: 10px;
`

export const ContactUsMenuItem = styled.a`
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 14px;
  display: flex;
  align-items: center;

  &:focus {
    text-decoration: none;
    color: var(--secondary-color);
  }

  @media (max-width: ${breakpoints.l}px) {
    font-size: 13px;
    ${({ faq }) =>
      faq
        ? 'display: block; text-align:center; margin: 40px 0;'
        : 'display:inline-flex; width: 50%; justify-content: center;'};
  }
`

export const MenuLink = styled(Link)`
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 14px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 13px;
    display: block;
    text-align: center;
    margin: 20px 0;
  }
`
