import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  SlideoutMenuContainer,
  MenuItem,
  MenuItemContact,
  SubmenuItem,
} from './styles'

const SlideoutMenu = props => {
  const { isVisible, setMenuVisible } = props
  const menuRef = useRef(null)

  const handleClickOutside = e => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setMenuVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind event
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <SlideoutMenuContainer ref={menuRef} isVisible={isVisible}>
      <MenuItem to={`/`} onClick={() => setMenuVisible(false)}>
        Home
      </MenuItem>
      <MenuItem to={`/categories/`} onClick={() => setMenuVisible(false)}>
        Products
      </MenuItem>
      <SubmenuItem
        to={`/categories/jewelry/`}
        onClick={() => setMenuVisible(false)}
      >
        Jewelry
      </SubmenuItem>
      <SubmenuItem
        to={`/product/blackwork-scarves/`}
        onClick={() => setMenuVisible(false)}
      >
        Scarves
      </SubmenuItem>
      <SubmenuItem to={`/categories/`} onClick={() => setMenuVisible(false)}>
        All Products
      </SubmenuItem>
      <MenuItem to={`/about/`} onClick={() => setMenuVisible(false)}>
        About Us
      </MenuItem>
      <MenuItem
        to={`https://sood_clothing_co.leaddyno.com/`}
        onClick={() => setMenuVisible(false)}
      >
        Affiliate Program
      </MenuItem>
      <MenuItemContact
        href={`mailto:sood.clothing.co@gmail.com`}
        onClick={() => setMenuVisible(false)}
      >
        Contact Us
      </MenuItemContact>
      <MenuItem to={`/faqs/`} onClick={() => setMenuVisible(false)}>
        FAQs
      </MenuItem>
    </SlideoutMenuContainer>
  )
}

SlideoutMenu.propTypes = {
  siteTitle: PropTypes.string,
}

export default SlideoutMenu
