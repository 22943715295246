import React, { useContext, useState, useEffect } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import PromoBanner from '../PromoBanner'
import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  Container,
  IconContainer,
  MenuLink,
  Wrapper,
  CartButton,
} from './styles'

import MenuIcon from './../../assets/menu.inline.svg'
import CartIcon from './../../assets/cart.inline.svg'
import MenuBlackIcon from './../../assets/menu-black.inline.svg'
import CartBlackIcon from './../../assets/cart-black.inline.svg'
import BrandLogo from './../../assets/soodLogo.inline.svg'

const enablePromoBanner = false

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({
  location,
  openMenu,
  isMenuVisible,
  setPopupVisible,
  isPopupVisible,
}) => {
  const [hasItems, quantity] = useQuantity()
  const [isHomePage, setIsHomePage] = useState(true)
  // const isHomePage = location === '/'

  useEffect(() => {
    if (location === '/') {
      setIsHomePage(true)
    } else {
      setIsHomePage(false)
    }
  }, [location])

  return (
    <>
      {enablePromoBanner && <PromoBanner />}
      <Wrapper
        isHomePage={isHomePage}
        className={isHomePage ? 'isHomePage' : 'isNotHomePage'}
        hasPromo={enablePromoBanner}
      >
        <Container>
          <IconContainer
            onClick={() => openMenu(true)}
            isMenuVisible={isMenuVisible}
          >
            {isHomePage ? (
              <MenuIcon
                style={{
                  width: 35,
                  height: 'auto',
                }}
              />
            ) : (
              <MenuBlackIcon
                style={{
                  width: 35,
                  height: 'auto',
                }}
              />
            )}
          </IconContainer>
          {isHomePage ? (
            ''
          ) : (
            <MenuLink to="/">
              <BrandLogo
                style={{ height: '35px', marginTop: '5px', marginLeft: '23px' }}
              />
            </MenuLink>
          )}
          <CartButton
            onClick={() => {
              setPopupVisible(!isPopupVisible)
            }}
            style={{ cursor: 'pointer', alignSelf: 'center' }}
          >
            {hasItems && (
              <CartCounter isHomePage={isHomePage}>{quantity}</CartCounter>
            )}
            {isHomePage ? (
              <CartIcon
                style={{
                  width: 30,
                  height: 'auto',
                }}
              />
            ) : (
              <CartBlackIcon
                style={{
                  width: 30,
                  height: 'auto',
                }}
              />
            )}
          </CartButton>
        </Container>
      </Wrapper>
    </>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
